<template>
  <base-layout>
    <ion-content id="subpage-detail" class="has-header has-subheader has-bottom-banner" >
  <session-list :sessions="sessions"></session-list>
      <h4 class="item" ><strong><span >{{abstract.code}} - </span> <span v-html="abstract.title"></span></strong></h4>

      <div v-html="abstract.text"></div>
    </ion-content>
  </base-layout>
</template>

<script>
import { IonContent } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import moment from "moment";
import SessionList from "@/components/SessionList";

export default defineComponent({
  name: "AbstractDetail",
  data() {
    return {
      abstract: {},
      sessions:[]
    };
  },
  components: {
    IonContent,
    "session-list": SessionList,
  },
  methods: {
    ...mapActions("abstracts", [
      "getAbstract",
        "getSessionForAbstract"
    ])
  },
  
  async created() {
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Abstract Detail') {
          this.moment = moment;
          this.abstract = await this.getAbstract(this.$route.params.id);
          this.sessions = await this.getSessionForAbstract(this.$route.params.id);
        }
      }
    }
  }
});
</script>
<style scoped>
#subpage-detail{
--padding-start:10px;
--padding-end:10px;
--padding-top:10px;
--padding-bottom:10px;
}
  
</style>